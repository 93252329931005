import React  ,{useState ,useEffect}from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch ,useSelector } from 'react-redux';
import Message from '../Components/Message';
import Loader from '../Components/Loader';
import { registerLoanProduct } from '../actions/settingAction';
import { ErrorMessage ,successMessage } from '../Components/Message';
import { PRODUCT_REGISTRATION_RESET ,PRODUCT_RESET_UPDATE } from '../constants/settingConstants';
import { LoanproductList ,updateLoanProductAction } from '../actions/settingAction';


function LoanProduct() {

  const dispatch=useDispatch()

  const ChartOfAccountReducer = useSelector(state => state. ChartOfAccountReducer)
  const {loadingchartOfAccount ,chartOfAccountsu ,chartOfAccount}= ChartOfAccountReducer

   const registerProductReducer = useSelector(state => state.registerProductReducer)
   const {registerProductLoading ,productSuccess  ,registerProductInfo ,error}=registerProductReducer

  const productReducer = useSelector(state => state.productReducer)
  const { products ,loadingproduct }=productReducer

  const updateProduct = useSelector(state => state.updateProduct)
  const { productUpdateLoading ,productUpdate }=updateProduct

 


  const [loan ,setloan]=useState(false)
  const[Loanname , setLoanname]=useState('')
  const[MapAccount ,setMapAccount]=useState('')
  const[AmountLimit ,setAmountLimit]=useState('')
  const[Interestrates ,setInterestrates]=useState('')
  const [Repaymentperiod ,setRepaymentperiod]=useState('')

  const openloan=()=>{
    setloan(true)
  }

  const closeloan=()=>{
    setloan(false)
    setselectedLoanProduct('')
  }

  useEffect(()=>{

    dispatch(LoanproductList())

  },[dispatch])


  const saveLoanProduct=()=>{

    if(Loanname=='' || MapAccount=='' || AmountLimit=='' || Interestrates==''|| Repaymentperiod=='' ){

      ErrorMessage('Please Enter All Required Fields' )
    
    }else {

      dispatch(registerLoanProduct(Loanname ,MapAccount , AmountLimit ,Repaymentperiod , Interestrates))
    }

  }


const updateLoanProduct=()=>{

  if(Loanname=='' || MapAccount=='' || AmountLimit=='' || Interestrates==''|| Repaymentperiod=='' ){

    ErrorMessage('Please Enter All Required Fields' )
  
  }else {

    let loan=Loanname
    let map=MapAccount
    let limit=AmountLimit
    let rate=Interestrates
    let months=Repaymentperiod

    dispatch(updateLoanProductAction({trans_code:selectedLoanProduct.trans_code , loan ,map , limit ,months , rate}))
  }

  
  
}


  useEffect(()=>{

    if(productSuccess){

      successMessage('Loan Product Successfully Saved')
      dispatch({type:PRODUCT_REGISTRATION_RESET})
      dispatch(LoanproductList())
      setLoanname('')
      setMapAccount('')
      setAmountLimit('')
      setRepaymentperiod('')
      setInterestrates('')
    }

  },[registerProductReducer])


  useEffect(()=>{

    if(productUpdate){

      successMessage('Loan Product Successfully Updated')
      //dispatch(LoanproductList())
      setLoanname('')
      setMapAccount('')
      setAmountLimit('')
      setRepaymentperiod('')
      setInterestrates('')
      //dispatch({type:PRODUCT_RESET_UPDATE})
    }

  },[updateProduct])



const [selectedLoanProduct , setselectedLoanProduct]=useState('')

  const getLoanProductDetails=(product)=>{
    
    setselectedLoanProduct(product)
      setLoanname(product.loan)
      setMapAccount(product.map)
      setAmountLimit(product.limit)
      setRepaymentperiod(product.months)
      setInterestrates(product.rate)
      openloan()
  }

  return (
    <div>

<h3>Loan Products</h3>

                    
<hr/>

<button onClick={openloan} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Loan Products </button>


<div className='solcon overflow-auto vh-100'>


{loadingproduct && <Loader/>}
{productUpdateLoading && <Loader/>}


<div className='bra row me-2'>

{products && products.map(product=>(

<div className='col-md-4 mb-4'>
    <div className="card">
        <div className="card-body">
        <h4 className="card-title">{product.product_name}</h4>
        <ul className="list-group">
            <li className="list-group-item">Loan Product Code : {product.trans_code}</li>

             <h5 className="card-title">{product.loan}</h5>
            <li className="list-group-item"> Loan Amount Limit: {product.limit}</li>
            <li className="list-group-item"> Repayment period(In Months): {product.months}</li> 
            <li className="list-group-item"> Interest rates(%): {product.rate}</li> 
        </ul>

        </div>
        <button  onClick={()=>getLoanProductDetails(product)} className='show_info_btn'>View Product Details</button>
    </div>
    </div>

                
))}

</div>


            
</div>
       
<Modal  size='lg' show={loan} onHide={closeloan} backdrop="static" keyboard={false}>
            <Modal.Header  closeButton>
                <Modal.Title>Loan Information</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className=' card'>
                        <div className='card-body'>

                        {registerProductLoading && <Loader/>}

                        <div className='row'>
                        <div className='col-md-6 mb-3'>

                        <div className='form-group '>
                                <strong className='lable' for='product Name'><samp className='text-danger'>* </samp> Loan Product</strong>
                                <input type='text' name='product Name' required className='form-control' value={Loanname} onChange={(e)=>setLoanname(e.target.value)} />
                            </div>

                        </div>

                        <div className='col-md-6'>

                      <div className='form-group '>
                      <strong className='lable' for='product Name'><samp className='text-danger'>* </samp> Map Accounts</strong>

                          <select required  className='form-control'  value={MapAccount} onChange={(e)=>setMapAccount(e.target.value)}  >
                                  <option required disabled selected value="" >Select</option>
                                  {chartOfAccount.map((chart)=>(
                                      <option value={chart.name}>{chart.name}</option>
                                  ))} 
                                      
                              </select>
                      </div>

                      </div>

                      <div className='col-md-6 mb-3'>

                        <div className='form-group '>
                                <strong className='lable' for='product Name'><samp className='text-danger'>* </samp> Loan Amount Limit</strong>
                                <input type='number' name='product Name' required className='form-control'  value={AmountLimit} onChange={(e)=>setAmountLimit(e.target.value)} />
                            </div>

                        </div>

                        <div className='col-md-6 mb-3'>

                        <div className='form-group '>
                                <strong className='lable' for='product Name'><samp className='text-danger'>* </samp>  Interest rates(%)</strong>
                                <input type='number' name='product Name' required className='form-control' value={Interestrates} onChange={(e)=>setInterestrates(e.target.value)} />
                            </div>

                        </div>

                        <div className='col-md-6 mb-3'>

                        <div className='form-group '>
                                <strong className='lable' for='product Name'><samp className='text-danger'>* </samp> Repayment period(In Months)</strong>
                                <input type='number' name='product Name' required className='form-control' value={Repaymentperiod} onChange={(e)=>setRepaymentperiod(e.target.value)} />
                            </div>

                        </div>

                        </div>

                        {selectedLoanProduct=='' ?  
                        <button onClick={saveLoanProduct} className="show_info_btn mt-3">Save Loan Product</button> : 
                        <button onClick={updateLoanProduct} className="show_info_btn mt-3">Update Loan Product</button>}
                       

                    </div>
                    </div>

                </Modal.Body>
                


        </Modal>

      
    </div>
  )
}

export default LoanProduct
