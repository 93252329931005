import React, { useEffect } from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import { loadloanApricat } from '../actions/customerAccountAction';
import { useState } from 'react';
import underReview from '../images/underReview.jpg'
import approve from '../images/approve.jpg'
import disapproved from '../images/disapproved.jpg'
import { Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { cash } from './NumberFormat';
import { disburese_loan ,savePaymentSchedule } from '../actions/settingAction';
import { ErrorMessage } from './Message';
import Swal from 'sweetalert2';
import { AccountAountServicerAction } from '../actions/customerAccountAction';
import Loader from './Loader';
import { SaveAccountTransaction } from '../actions/customerAccountAction';
import { ToWords } from 'to-words';
import { PRODUCT_REGISTRATION_RESET  } from '../constants/settingConstants';
import { CUSTOMER_ACCOUNTS_RESET_DETAILS } from '../constants/customerAccountConstants';
import { ACCOUNT_TRANSACTION_RESET } from '../constants/customerAccountConstants';
import { successMessage } from './Message';
import { sms_disbursement } from './Message';
import { numberFormat } from './NumberFormat';

function LoanDisbursement() {

  const dispatch=useDispatch()
   let dat2 = moment().format('YYYY-MM-DD')
   let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
   let save_aproval="True"


  const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
  const { customerAccounts ,customerAccountsLoading }=CustomerAccountReducer

  const accountAutoServiceReducer = useSelector(state => state. accountAutoServiceReducer)
  const {accountAutoServicerLoading ,accountAutoServicer }= accountAutoServiceReducer

  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo } = userProfileReducer

  const [loadloanApricatInfor ,setloadloanApricatInfor]=useState()

  const [DisbursedAmount , setDisbursedAmount]=useState('')

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo }=userLogin

 const accountTransactionReducer = useSelector(state => state.accountTransactionReducer)
const {transactionLoading,transactionInfo ,depositsu}=accountTransactionReducer

const registerProductReducer = useSelector(state => state.registerProductReducer)
const {registerProductLoading ,productSuccess  ,registerProductInfo ,error}=registerProductReducer


  const toWords = new ToWords({
          localeCode: 'en-US',
          converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
              // can be used to override defaults for the selected locale
              name: 'Ghana Cedis',
              plural: 'Ghana Cedis',
              symbol: '₹',
              fractionalUnit: {
                name: 'Pesewas',
                plural: 'Pesewas',
                symbol: '',
              },
            },
          },
        })

  useEffect(()=>{
     dispatch(loadloanApricat())
  },[dispatch])


   useEffect(()=>{
  
      if(customerAccounts){
      setloadloanApricatInfor(customerAccounts.filter(obj=>obj.Status==2))
    }
    },[CustomerAccountReducer])


 const [SMS ,setSMS]=useState('')

  useEffect(()=>{
  
    const smss=accountAutoServicer.find(obj=>obj.service_name =='SMS alert on transactions')
  
    if(smss){
      setSMS(smss)
    }else {
      setSMS(smss)
    }
  
    console.log(smss)
  
  },[accountAutoServiceReducer])


    const [loanInfo ,setloanInfo]=useState(false)

    const openloanInfo=()=>{
      
      setloanInfo(true)
    }

    const closeloanInfo=()=>{
      setloadschedule([])
      setmaturity('')
      setloanStartDate('')
      setloanInfo(false)
    }

    const [selecteddis ,setselecteddis]=useState('')

    const disbursmentInfo=(loan)=>{
      setselecteddis(loan)
     
      dispatch(AccountAountServicerAction(loan.Acc ,save_aproval))
      openloanInfo()
    }

    useEffect(()=>{

      let amonutdisbu= (Number(selecteddis.AmountApproved)) - (Number(selecteddis.BusinessSupport) + Number(selecteddis.LoanProccssing) +Number(selecteddis.InsuranceFee))
      //console.log(amonutdisbu)
      setDisbursedAmount(amonutdisbu)

    },[selecteddis])

    const [loanStartDate ,setloanStartDate]=useState('')
  
    const [maturity ,setmaturity]=useState('')
    const [loadschedule ,setloadschedule]=useState([])

    let sch=[]
  const handloanStartDate=(e)=>{

    setloadschedule([])
    sch=[]
    setloanStartDate(e.target.value)

    let totalInterest =Number(selecteddis.AmountApproved) /100 * Number(selecteddis.rate)

    let monthlyInterest= Number(totalInterest) / Number(selecteddis.Period)

    let monthlyprinciple=Number(selecteddis.AmountApproved)/Number(selecteddis.Period)

    let instalment=Number(monthlyInterest)+ Number(monthlyprinciple)

    let balance = Number(selecteddis.AmountApproved) +Number(totalInterest)
  
    let currentDate = moment(e.target.value);
   
    for (let x = 0; x < Number(selecteddis.Period); x++) {
      currentDate= moment(currentDate).add(1, 'M');
      //console.log(currentDate.format('YYYY-MM-DD'));
      balance=(balance-instalment).toFixed(2)
     
        sch.push({
        monthNumber:x+1 ,
        date:currentDate.format('YYYY-MM-DD') ,
        instalment:instalment ,
        monthlyInterest:monthlyInterest,
        monthlyprinciple:monthlyprinciple,
        balance:balance
      })
    }

    setloadschedule(sch)
    

  
   setmaturity(sch[Number(selecteddis.Period)-1].date)
 // console.log(sch[Number(selecteddis.Period)-1])
   
  }

  const loanSchColumn =[
   
    {
     name:'#' ,
     selector:row=>row.monthNumber ,
     width: "60px" 
     ,wrap:true 
  
    } ,
  
     {
      name:'DUE DATE' ,
      selector:row=>row.date
      ,wrap:true
     },
  
     {
      name:'INSTALMENT ',
      selector:row=>cash(Number(row.instalment).toFixed(2))
      ,wrap:true ,
      sortable:true
     } ,

     {
      name:'INTEREST DUE ',
      selector:row=> cash(Number(row.monthlyInterest).toFixed(2))
      ,wrap:true ,
      sortable:true
     } ,

     {
      name:'PRINCIPAL DUE ',
      selector:row=> cash(Number(row.monthlyprinciple).toFixed(2))
      ,wrap:true ,
      sortable:true
     } ,

     {
      name:'BALANCE',
      selector:row=> cash(Number(row.balance))
      ,wrap:true ,
      sortable:true
     } 
  
    
  ]

  const saveDisburse=()=>{

    if(loanStartDate=='' || loadschedule=='' ){
      
      ErrorMessage('Select Loan Start Date')
    
    }else {


       const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
              confirmButton: "btn btn-success mx-4",
              cancelButton: "btn btn-danger"
              },
              buttonsStyling: false
          });
          swalWithBootstrapButtons.fire({
              title: "Confirm",
              text: "Are You Sure You Want to Disburese The Selected Loan ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
           
        
          }).then((result) => {

        
              if (result.isConfirmed) {
      
                let Disburese=1
                let loan_start_date=loanStartDate
                let maturity_date =maturity
                let Disburese_amount =DisbursedAmount

                dispatch(disburese_loan({trans_code:selecteddis.trans_code , Disburese , loan_start_date , maturity_date , Disburese_amount }))
          
                for(let x = 0; x <loadschedule.length; x++){

                let loan_application_id=selecteddis.trans_code
                let account_number= selecteddis.Acc
                let account_name=selecteddis.Accname
                let monthNumber = loadschedule[x].monthNumber
                let date = loadschedule[x].date
                let instalment = loadschedule[x].instalment
                let interest = loadschedule[x].monthlyInterest
                let principal = loadschedule[x].monthlyprinciple
                let balance = loadschedule[x].balance
                
                

                dispatch(savePaymentSchedule(loan_application_id ,account_number,account_name ,monthNumber ,date , instalment , interest,principal,balance))
              
              }

              let des='loan disbursed'
              let user=userInfo.username

              let words = toWords.convert(DisbursedAmount, { currency: true });

             
              dispatch(SaveAccountTransaction(DisbursedAmount ,dat ,DisbursedAmount ,'0',userProfileInfo.branch , selecteddis.Acc,des ,dat2 ,selecteddis.Accname,'-' 
                ,user ,userProfileInfo.branch,'-','-','-', '-' ,'0' , '0' ,'0' ,'0' ,'0' ,'0' ,'0' ,'0', '0' , '0' ,'0' ,'0' , '0' ,words))
                    
                
             
              } else if (result.dismiss === Swal.DismissReason.cancel) {
        
             
              }
              
          });

     

    }

  }


  useEffect(()=>{
  
  
      if(depositsu){

        successMessage('Loan disbursement Successfully Save')


         if(SMS){
           
            let tel= SMS.tel
            console.log(tel)
            let cname=selecteddis.Accname
            let acno=selecteddis.Acc.substr(4, 5);
            let drcr='Credited'
            let desc='Loan approved and disbursed'
            let dat =moment().format('YYYY-MM-DD ,h:mm:ss a')
        
            var amountt=numberFormat(DisbursedAmount).split('$')[1]
          
            sms_disbursement(tel ,cname,acno,drcr,amountt,desc,dat)
          }
        
        dispatch({type:PRODUCT_REGISTRATION_RESET})
        dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
         dispatch({type:ACCOUNT_TRANSACTION_RESET})
         setSMS('')
        dispatch(loadloanApricat())
        closeloanInfo()
      }
     
  
    },[accountTransactionReducer])



     useEffect(()=>{
    
    
        if(productSuccess){
    
          dispatch({type:PRODUCT_REGISTRATION_RESET})
          dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
          
        }
       
    
      },[registerProductReducer])

  return (
    <div>
        <h3>Loan Disbursement</h3>
        <hr/>

        <div className='row mb-4 mt-2'>
        <div className='col-md-3'>
       
        <input name="Name"  required type="text" className="form-control  "  placeholder='Search Applicant' />
        </div>

        </div>

        <div className='solcon overflow-auto vh-100' >
        <div className=''>

          <div className='bra row me-2'>
                  
                    { loadloanApricatInfor && loadloanApricatInfor.map(loan=>(
                      
                    <div className='col-md-3 mb-3'>
                        <div className="card">
                            <div className="card-body">
                            <h4 className="card-title">{loan.ty} ........<img width={70} height={70} src={loan.Status ==1 ? underReview : loan.Status ==2 ? approve :  loan.Status ==3 ? disapproved :'' } className="rounded-circle"/> </h4>
                            <ul className="list-group">
                                <li className="list-group-item">Date : {loan.dat}</li>
          
                                <h5 className="card-title">{loan.Aname}</h5>
                                <li className="list-group-item"> Telephone: {loan.Atel}</li>
                                <li className="list-group-item"> Account Number: {loan.Acc}</li> 
                                <li className="list-group-item">Account Name: {loan.Accname}</li> 
                                <li className="list-group-item">Guarantor: {loan.Cname}</li> 
                            </ul>
          
                            </div>

                            {loan.Disburese ==0 ? <button onClick={()=>disbursmentInfo(loan)} className='show_info_btn'>Disbursement Loan Information</button>: '' }
                            {loan.Disburese ==1 ? <button  className='show_info_btn'>Disbursement Report</button>: '' }
                           
                        </div>
                        </div>
                                    
                    ))}
          
                  </div>

        </div>
        </div>

        <Modal size="lg" show={loanInfo} onHide={closeloanInfo} dialogClassName="my-modal"  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title>Disbursement Loan Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='card'>

              {accountAutoServicerLoading  && <Loader/>}
              {registerProductLoading  && <Loader/>}
              {transactionLoading  && <Loader/>}
              
              
                <div className='card-body'>
                <div className='row'>
              <div className='col-md-6'>

              <div className='row'>
                <div className='card-header mb-3'>LOAN INFORMATION</div>
                <div className='col-md-6'>
                  <label className='lable'  for='Surname'>Applicant Name</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.Aname} disabled />

                  <label  className='lable'  for='Surname'>Account Number</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.Acc} disabled />
                </div>

                <div className='col-md-6'>

                <label  className='lable'  for='Surname'>Account Name</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.Accname} disabled />


                <label  className='lable' for='Surname'>Guarantor</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.Cname} disabled />
               
                </div>
              </div>

              <hr/>

              <div className='row'>
                <div className='col-md-6'>

                <label  className='lable' for='Surname'>Amount Applied</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.amoreq}
                    disabled
                    decimalScale={2}
                    
                    />

                <label  className='lable' for='Surname'>Amount Approved</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.AmountApproved}
                    disabled
                    decimalScale={2}
                    
                    />

                <label  className='lable' for='Surname'>Cash Lien</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.CashLien}
                    disabled
                    decimalScale={2}
                    
                    />


                <label  className='lable'  for='Surname'> Interest rates(%)</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.rate} disabled />


                <label  className='lable' for='Surname'>Loan Start Date</label>
                <input name="Name" required type="date" className="form-control mb-3" value={loanStartDate} onChange={handloanStartDate} />


                <label  className='lable' for='Surname'>Disbursed Amount</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={DisbursedAmount}
                    decimalScale={2}
                    />

                </div>



                <div className='col-md-6'>

                <label  className='lable' for='Surname'>Payable Within(Months)</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={selecteddis.Period} disabled />


                <label  className='lable' for='Surname'>Business Support Fee</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.BusinessSupport}
                    disabled
                    decimalScale={2}
                    />


                  <label  className='lable' for='Surname'>Loan Proccssing Fee</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.LoanProccssing}
                    disabled
                    decimalScale={2}
                    />

                  <label  className='lable' for='Surname'>Insurance Fee</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control text-end mb-3'
                    value={selecteddis.InsuranceFee}
                    disabled
                    decimalScale={2}
                    />

                <label  className='lable' for='Surname'>Maturity Date</label>
                <input name="Name"  type="text" className="form-control mb-3" value={maturity} disabled  />
                
               
                { transactionLoading ? '' :   <button onClick={saveDisburse} className='show_info_btn mt-3 w-100'>Disburse Loan</button> }
              
               
                </div>
              </div>

              </div>
              <div className='col-md-6'>
                    
                <div class="card">
                <div class="card-body">
                <h5 className="card-title">Loan Payment Schedule</h5>
              
                      <DataTable className='rdt_Table' columns={loanSchColumn}  data={loadschedule=='' ? loadschedule : loadschedule}   
                        selectableRowsHighlight 
                        highlightOnHover
                        fixedHeader
                        pagination 
                        paginationPerPage={12}
                          //onSelectedRowsChange={getSelectedSubjects}
                        // selectableRows  
                          //selectableRowSelected={getPatientInfo} 
                          //</div> onRowClicked={getPatientInfo}
                        responsive={true}
                          >
          
                      </DataTable>
                    

                </div>
              </div>
             
             
              </div>
              </div>
                </div>
              </div>
              
              
            
            </Modal.Body>
                        
    </Modal>

    </div>
  )
}

export default LoanDisbursement