import React, { useEffect } from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import { loadCustomerAccountNumberAction } from '../actions/customerAccountAction';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { getMemberDetailsAction } from '../actions/memberAction';
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { useState } from 'react';
import Loader from '../Components/Loader';
import { LoanproductList } from '../actions/settingAction';
import CurrencyInput from 'react-currency-input-field';
import Stepper from "awesome-react-stepper";
import { Modal } from 'react-bootstrap';
import { memberList } from '../actions/memberAction';
import moment from 'moment';
import { registerLoanAppricate ,updateLoanAppricate } from '../actions/settingAction';
import { ErrorMessage, successMessage } from './Message';
import { PRODUCT_REGISTRATION_RESET } from '../constants/settingConstants';
import { CUSTOMER_ACCOUNTS_RESET_DETAILS } from '../constants/customerAccountConstants';
import { loadloanApricat } from '../actions/customerAccountAction';
import underReview from '../images/underReview.jpg'
import approve from '../images/approve.jpg'
import disapproved from '../images/disapproved.jpg'
import { VAULT_BALANCE_RESET } from '../constants/vaultConstants';

function LoanApplication() {

const dispatch=useDispatch()
let save_aproval="True"

let today = moment().format('YYYY-MM-DD')

const [LoanForm , setLoanForm]=useState(false)

const openloanForm=()=>{
    setLoanForm(true)
}

const closeloanForm=()=>{
     clear()
    setLoanForm(false)

}
  const CustomerAccountDetailsReducer = useSelector(state => state. CustomerAccountDetailsReducer)
  const {CustomerAccountDetails ,CustomerAccountDetailsLoading ,loadsu }= CustomerAccountDetailsReducer
    
  
    const CustomerAccountNumberReducer = useSelector(state => state.CustomerAccountNumberReducer)
    const {accountNumber}=CustomerAccountNumberReducer

    const [cAccNo,setcAccNo]=useState(accountNumber)


    
    const MembereReducer = useSelector(state => state. MembereReducer)
    const {MemberInfo ,memberLoading ,memberSuccess ,MemberError}= MembereReducer


    const productReducer = useSelector(state => state.productReducer)
    const { products ,loadingproduct }=productReducer


    const MembereDetailsReducer = useSelector(state => state. MembereDetailsReducer)
    const {MemberDetailsInfo ,MemberDetailssuccess ,memberDetailsLoading}= MembereDetailsReducer

    const registerProductReducer = useSelector(state => state.registerProductReducer)
    const {registerProductLoading ,productSuccess  ,registerProductInfo ,error}=registerProductReducer


    const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
    const { customerAccounts ,customerAccountsLoading }=CustomerAccountReducer

    const [loadloanApricatInfor ,setloadloanApricatInfor]=useState()

    const [appricatName ,setappricatName]=useState()

    const [appricatStatus ,setappricatStatus]=useState()

useEffect(()=>{

     dispatch(loadCustomerAccountNumberAction(save_aproval))
     dispatch(LoanproductList())
     dispatch(memberList())

     dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
   
     dispatch(loadloanApricat())

     dispatch({type:VAULT_BALANCE_RESET})

    },[dispatch])


  useEffect(()=>{

    setloadloanApricatInfor(customerAccounts)

  },[CustomerAccountReducer])

  

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    //console.log(string, results)
  }
  
  const handleOnHover = (result) => {
    // the item hovered
   // console.log(result)
  }
  
const [appricant ,setappricant]=useState(false)
const handleOnSelect = (accountNumber) => {
    
  //clear()

   try {
   
    
  dispatch(getMemberDetailsAction(accountNumber.trans_code))
  setappricant(true)
    

   } catch (error) {
    
   }
   
  }

const [Guarantor ,setGuarantor]=useState(false)
const handleOnSelectGua = (accountNumber) => {

  
     try {
     
      dispatch(getMemberDetailsAction(accountNumber.trans_code))  
      setGuarantor(true)
     } catch (error) {
      
     }
     
    }
  
  const handleOnFocus = () => {
    //console.log('Focused')
  }
  
const formatResult = (member) => {
    return (
      <>
  
        <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {member.name} </span>
        <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {member.tel} </span>
        
      </>
    )
  }




  const formatResultAC = (accountNumber) => {
    return (
      <>
  
       
      <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.name} </span>
      <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {accountNumber.account_name} </span>
        
      </>
    )
  }


  const [accInfo ,setaccInfo]=useState(false)
  const handleOnSelectt = (accountNumber) => {
  
     try {
      
      dispatch(getCustomerDetailsAction(accountNumber.trans_code))
      setaccInfo(true)
   
     } catch (error) {
      
     }
     
    }
    


  const clear=()=>{

      setAname('')
      setAgender('')
      setAdob('')
      setAage('')
      setAtel('')
      setAmar('')
      setAid('')
      setAidn('')
      setAres('')
      setAnland('')

      setAcc('')
      setAccname('')
      setpro('')
      setAccty('')

      setCname('')
      setCgender('')
      setCdob('')
      setCage('') 
      setCtel('')
      setCmar('')
      setCid('')
      setCidn('')
      setCres('')
      setCnland('')

      setty('')
      setloanLimit('')
      setPeriod('')
      setrate('')
      setamoreq('0.00')
      setloanty('')
      setloanpur('')
      setCollateral('')
      setCollateralty('')
    
      setGaccNumber('')
      setgetselectedRow('')

  }

  const clearg=()=>{

   
  
    setCname('')
    setCgender('')
    setCdob('')
    setCage('') 
    setCtel('')
    setCmar('')
    setCid('')
    setCidn('')
    setCres('')
    setCnland('')
      
  
  }

  const clearA=()=>{

    setAname('')
    setAgender('')
    setAdob('')
    setAage('')
    setAtel('')
    setAmar('')
    setAid('')
    setAidn('')
    setAres('')
    setAnland('')

  
  }

  const clearACC=()=>{
  
    setAcc('')
    setAccname('')
    setpro('')
    setAccty('')
  
   
  }

const [Aname ,setAname]=useState('')
const [Agender ,setAgender]=useState('')

const [Adob ,setAdob]=useState('')
const [Aage ,setAage]=useState('')

const [Atel ,setAtel]=useState('')
const [Amar ,setAmar]=useState('')

const [Aid ,setAid]=useState('')
const [Aidn ,setAidn]=useState('')

const [Ares ,setAres]=useState('')
const [Anland ,setAnland]=useState('')


const [Cname ,setCname]=useState('')
const [Cgender ,setCgender]=useState('')

const [Cdob ,setCdob]=useState('')
const [Cage ,setCage]=useState('')

const [Ctel ,setCtel]=useState('')
const [Cmar ,setCmar]=useState('')

const [Cid ,setCid]=useState('')
const [Cidn ,setCidn]=useState('')

const [Cres ,setCres]=useState('')
const [Cnland ,setCnland]=useState('')


const [Acc,setAcc]=useState('')
const [Accname ,setAccname]=useState('')
const [pro,setpro]=useState('')
const [Accty ,setAccty]=useState('')

const [selectedloan ,setselectedloan]=useState('')
const [loanInfo ,setloanInfo]=useState('')

const [ty , setty]=useState('')
const [loanLimit , setloanLimit]=useState('')
const [Period , setPeriod]=useState('')
const [rate , setrate]=useState('')
const[amoreq ,setamoreq]=useState('0.00')
const[loanty ,setloanty]=useState('')
const[loanpur ,setloanpur]=useState('')
const[Collateral ,setCollateral]=useState('')
const[Collateralty ,setCollateralty]=useState('')

const [GaccNumber ,setGaccNumber]=useState('')

const handelselectedloan =(e)=>{

    setselectedloan(e.target.value)
    setloanInfo(products.find(obj=>obj.loan==e.target.value))
   
}

useEffect(()=>{

  setty(loanInfo.loan)
  setloanLimit(loanInfo.limit)
  setPeriod(loanInfo.months)
  setrate(loanInfo.rate)

},[loanInfo])



useEffect(()=>{

  if(appricant){
  setAname(MemberDetailsInfo.name)
  setAgender(MemberDetailsInfo.sex)
  setAdob(MemberDetailsInfo.dob)
  setAage(moment().diff(MemberDetailsInfo.dob, 'years'))
  setAtel(MemberDetailsInfo.tel)
  setAmar(MemberDetailsInfo.mar)
  setAid(MemberDetailsInfo.card_ty)
  setAidn(MemberDetailsInfo.card_Id)
  setAres(MemberDetailsInfo.r_add)
  setAnland(MemberDetailsInfo.nland)
  setappricant(false)
  }


  if(Guarantor){
    setCname(MemberDetailsInfo.name)
    setCgender(MemberDetailsInfo.sex)
    setCdob(MemberDetailsInfo.dob)
    setCage(moment().diff(MemberDetailsInfo.dob, 'years'))
    setCtel(MemberDetailsInfo.tel)
    setCmar(MemberDetailsInfo.mar)
    setCid(MemberDetailsInfo.card_ty)
    setCidn(MemberDetailsInfo.card_Id)
    setCres(MemberDetailsInfo.r_add)
    setCnland(MemberDetailsInfo.nland)
    setGuarantor(false)
    }

},[MemberDetailsInfo])


useEffect(()=>{

 if(loadsu){
  setAcc(CustomerAccountDetails.account_number)
  setAccname(CustomerAccountDetails.account_name)
  setpro(CustomerAccountDetails.product_name)
  setAccty(CustomerAccountDetails.acc_type)
}

},[CustomerAccountDetailsReducer])


const saveregisterLoanAppricate=()=>{
  if(Aname=='' || Agender=='' || Adob=='' || Aage=='' || Atel=='' ||Amar=='' || Aid=='' || Aidn=='' || Ares=='' || Anland=='' || Acc=='' || Accname=='' || pro=='' || Accty==''
    ||  ty=='' || loanLimit=='' || Period=='' || rate=='' || amoreq=='' || loanty== '' || loanpur=='' || Collateral=='' || Cname=='' || Cgender==''||Cdob=='' ||Cage=='' ||Ctel=='' 
    ||Cmar=='' || Cid==''|| Cidn==''|| Cres=='' || Cnland=='' || GaccNumber=='' || Acc=='' || Accname=='' || pro=='' || Accty==''  ){
    
     ErrorMessage('Please Enter All Required Fields')

    }else if(!(Atel.match('^[0-9]{10}$'))){

      ErrorMessage('Please provide valid Telephone Number')

    }else if(Collateral=='Other' && Collateralty=='' ){

      ErrorMessage('Please Specify The Type Of Collateral')

    }else if(!(GaccNumber.match('^[0-9]{13}$'))){

      ErrorMessage('Please provide valid Guarantor Account Number')

      
  }else{

    let dat2 = moment().format('YYYY-MM-DD')
    let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
    let status='1'

    dispatch(registerLoanAppricate(Aname ,Agender ,Adob ,Aage,Atel ,Amar ,Aid ,Aidn , Ares ,Anland ,Acc ,Accname,pro,Accty , 
      ty ,loanLimit ,Period , rate , amoreq , loanty , loanpur , Collateral , Collateralty , Cname , Cgender , Cdob , Cage , Ctel , Cmar , Cid,
      Cidn ,Cres ,Cnland ,  GaccNumber , dat ,dat2,status))
  }

  }
 
  useEffect(()=>{


    if(productSuccess){

      successMessage('Loan Application Save Successfully')
      clear()
      dispatch({type:PRODUCT_REGISTRATION_RESET})
      dispatch({type:CUSTOMER_ACCOUNTS_RESET_DETAILS})
      
      dispatch(loadloanApricat())
    }
   

  },[registerProductReducer])

  const calAge=(e)=>{

    setAage(moment().diff(e.target.value, 'years'))
    setAdob(e.target.value)

  }


  const GcalAge=(e)=>{

    setCage(moment().diff(e.target.value, 'years'))
    setCdob(e.target.value)

  }

const [getselectedRow , setgetselectedRow]=useState('')
const loadLoanDetails =(row)=>{

        setgetselectedRow(row)
        setAname(row.Aname)
        setAgender(row.Agender)
        setAdob(row.Adob)
        setAage(row.Aage)
        setAtel(row.Atel)
        setAmar(row.Amar)
        setAid(row.Aid)
        setAidn(row.Aidn)
        setAres(row.Ares)
        setAnland(row.Anland)

        setAcc(row.Acc)
        setAccname(row.Accname)
        setpro(row.pro)
        setAccty(row.Accty)

        setCname(row.Cname)
        setCgender(row.Cgender)
        setCdob(row.Cdob)
        setCage(row.Cage) 
        setCtel(row.Ctel)
        setCmar(row.Cmar)
        setCid(row.Cid)
        setCidn(row.Cidn)
        setCres(row.Cres)
        setCnland(row.Cnland)

        setty(row.ty)
        setloanLimit(row.loanLimit)
        setPeriod(row.Period)
        setrate(row.rate)
        setamoreq(row.amoreq)
        setloanty(row.loanty)
        setloanpur(row.loanpur)
        setCollateral(row.Collateral)
        setCollateralty(row.Collateralty)

        setGaccNumber(row.GaccNumber)

        openloanForm()
}


const updateLoanApprication =()=>{
  dispatch(updateLoanAppricate({trans_code:getselectedRow.trans_code , Aname ,Agender ,Adob ,Aage,Atel ,Amar ,Aid ,Aidn , Ares ,Anland  , 
    Acc ,Accname,pro,Accty ,ty ,loanLimit ,Period , rate , amoreq , loanty ,loanpur ,Collateral ,Collateralty ,
    Cname,Cgender,Cdob ,Cage ,Ctel,Cmar,Cid,Cidn,Cres,Cnland,GaccNumber}))
}


const searchbyAppricatName=(e)=>{
  
  setappricatName(e.target.value)

  const newData =customerAccounts.filter(row=>{

          
    return row.Aname.toLowerCase().match(e.target.value.toLowerCase())
          
      })
      setloadloanApricatInfor(newData)
}


const searchApriStatus=(e)=>{
  
  setappricatStatus(e.target.value)

  const newData =customerAccounts.filter(row=>{

          
    return row.Status.toLowerCase().match(e.target.value.toLowerCase())
          
      })
      setloadloanApricatInfor(newData)
}


  return (
    <div>

        <h3>Loan Application</h3>
        <hr/>
        
        <button  onClick={openloanForm} className='btn-class py-2 px-4 mt-2 mb-3'>Create New Loan Applicant</button>

       
        {customerAccountsLoading && <Loader/>}
        {loadingproduct && <Loader/>}
        {memberLoading && <Loader/>}
        

        <div className='row mb-4 mt-2'>
        <div className='col-md-3'>
       
        <input name="Name"  required type="text" className="form-control  "  placeholder='Search Applicant' value={appricatName} onChange={searchbyAppricatName}   />
        </div>

        <div className='col-md-3'>

        <select required  className='form-control'  value={appricatStatus} onChange={searchApriStatus} >
            <option selected value="Select" >Select</option>
            <option value="1">Loan Application Under underReview</option>
            <option value="2">Loan Application Approved</option>
            <option value="3">Loan Application Disapproved</option>
        </select>

        </div>
        </div>
  
        <div className='solcon overflow-auto vh-100' >
        <div className=''>

        <div className='bra row me-2'>
        
          { loadloanApricatInfor && loadloanApricatInfor.map(loan=>(
            
          <div className='col-md-3 mb-3'>
              <div className="card">
                  <div className="card-body">
                  <h4 className="card-title">{loan.ty} ........<img width={70} height={70} src={loan.Status ==1 ? underReview : loan.Status ==2 ? approve :  loan.Status ==3 ? disapproved :'' } className="rounded-circle"/> </h4>
                  <ul className="list-group">
                      <li className="list-group-item">Date : {loan.dat}</li>

                      <h5 className="card-title">{loan.Aname}</h5>
                      <li className="list-group-item"> Telephone: {loan.Atel}</li>
                      <li className="list-group-item"> Account Number: {loan.Acc}</li> 
                      <li className="list-group-item">Account Name: {loan.Accname}</li> 
                      <li className="list-group-item">Guarantor: {loan.Cname}</li> 
                  </ul>

                  </div>
                  {loan.Status !=1 ? '':  <button onClick={()=>loadLoanDetails(loan)} className='show_info_btn'>View Application Details</button>}
                 
              </div>
              </div>
                          
          ))}

        </div>

        </div>
        </div> 

        <Modal  size='lg' show={LoanForm} onHide={closeloanForm} backdrop="static" keyboard={false}>
            <Modal.Header  closeButton>
                <Modal.Title>Loan Application Form</Modal.Title>
                
                {memberDetailsLoading && <Loader/>}
                {CustomerAccountDetailsLoading && <Loader/>}
                {appricant && <Loader/>}
                {Guarantor && <Loader/>}
                {registerProductLoading && <Loader/>}

                
            </Modal.Header>
                <Modal.Body>


                <Stepper
                    strokeColor="#00000"
                    fillStroke="#012970"
                    activeColor="#06A3DA"
                    activeProgressBorder="2px solid #06A3DA"
                    defaultActiveStep='true'
                    
                    submitBtn={<button className="btn-class"> save </button>}
                    continueBtn={<button className="show_info_btn">Next Page</button>}
                    backBtn={<button className="show_info_btn">Previous Page</button>}

                    
                    onSubmit={(step) => registerProductLoading ? '' : getselectedRow=='' ? saveregisterLoanAppricate() :  updateLoanApprication()  }
                    
                    >

                    <div className="stepperSubDiv">

                    <h3 className='mb-3'>Applicant Information</h3>

                    {MemberInfo &&

                    <>

                     <div style={{ width: 400 }}>
                        <ReactSearchAutocomplete
                        items={MemberInfo}
                        // fuseOptions={{ keys: ["trans_code", "tel"] }}
                        // resultStringKeyName="tel"
                        
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={clearA}
                        autoFocus
                        
                        formatResult={formatResult}
                        placeholder='Search Applicant Name'
                        
                        />
                       
                    </div>
                    
                    </>
                    
                     }
                    <div className='row mt-4'>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Name</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Aname}  onChange={(e)=>setAname(e.target.value)} />
                    </div>
                    
                    

                    <div className='col-md-6'>

                    <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Gender</lable>
                              <select required  className='form-control' value={Agender}  onChange={(e)=>setAgender(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Famale">Famale</option>
                              </select>

                    </div>
                    
                    </div>

                    <div className='row'>
                        
                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Date Of Birth</label>
                    <input name="Name"  required type="date" className="form-control mb-3 " value={Adob}  onChange={calAge}  />
                    </div>
                    
                    

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Age</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Aage}  disabled />
                    </div>
                    
                    </div>
                    
                    <div className='row'>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Telephone</label>
                    <input name="Name"  required type="number" className="form-control mb-3 " value={Atel}  onChange={(e)=>setAtel(e.target.value)} />
                    </div>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Marital Status</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Amar}  onChange={(e)=>setAmar(e.target.value)} />
                    </div>

                    </div>

                    <div className='row'>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Identification Type</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Aid} onChange={(e)=>setAid(e.target.value)} />
                    </div>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Identification Number</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Aidn}  onChange={(e)=>setAidn(e.target.value)} />
                    </div>

                    </div>

                    <div className='row'>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Residential Address</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Ares}  onChange={(e)=>setAres(e.target.value)} />
                    </div>

                    <div className='col-md-6'>
                    <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Nearest Landmark</label>
                    <input name="Name"  required type="text" className="form-control mb-3 " value={Anland}  onChange={(e)=>setAnland(e.target.value)} />
                    </div>

                    </div>


                    </div>


                    <div className="stepperSubDiv">

                     
                    <h3 className='mb-3'>Account Infomation</h3>
                        
                    {cAccNo &&

                    <>

                    <div style={{ width: 400 }} className='mb-4'>
                        <ReactSearchAutocomplete
                        items={cAccNo}
                        // fuseOptions={{ keys: ["trans_code", "tel"] }}
                        // resultStringKeyName="tel"
                        
                        //onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelectt}
                        onFocus={handleOnFocus}
                        onClear={clearACC}
                        autoFocus
                        
                        formatResult={formatResultAC}
                        placeholder='Search Account Number'
                        
                        />
                      
                    </div>

                    </>

                  }

                <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Account Number</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={Acc} onChange={(e)=>setAcc(e.target.value)} disabled />

                <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Account Name</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={Accname} onChange={(e)=>setAccname(e.target.value)} disabled />

                <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Product Type</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={pro} onChange={(e)=>setpro(e.target.value)} disabled />

                <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Account Type</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={Accty} onChange={(e)=>setAccty(e.target.value)} disabled />


                 </div>


                 <div className="stepperSubDiv">

                     
                <h3 className='mb-3'>Details Of Loan Request</h3>
                    
              
                <label  className='lable' for='companyName'>Loan Type<samp className='text-danger'> * </samp></label>
                    <select required  className='form-control mb-3' value={ty} onChange={handelselectedloan} >
                            <option selected value="" >Select</option>
                            {products && products.map((loan)=>(
                            <option value={loan.loan}>{loan.loan}</option>
                        ))} 
                    </select>

                    <div className='row'>
                    <div className='col-md-4 sm-3'>
                    <label  className='lable' for='companyName'> Loan Amount Limit<samp className='text-danger'> * </samp></label>
                    <CurrencyInput
                    id="input-example"
                    name="input-name"
                    //placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    className='form-control mb-3'
                    value={loanLimit}
                    decimalScale={2}
                    disabled
                    />
                   </div>
                   <div className='col-md-4 sm-3'>
                    <label  className='lable' for='companyName'>Period(In Months)<samp className='text-danger'> * </samp></label>
                    <input type='text' className='form-control mb-3' value={Period}  disabled />
                    </div>

                    <div className='col-md-4 sm-3'>
                    <label  className='lable' for='companyName'>  Interest rates(%)<samp className='text-danger'> * </samp></label>
                    <input type='text' className='form-control mb-3' value={rate}  disabled />
                    </div>
                    </div>

                    
                    <label  className='lable' for='companyName'> Amount required <samp className='text-danger'> * </samp></label>
                    <input type='number' className='form-control mb-3' value={amoreq}  onChange={(e)=>setamoreq(e.target.value)} />
                    
                    
                    <label  className='lable' for='companyName'>Mode Of Payment<samp className='text-danger'> * </samp></label>
                    <select required  className='form-control mb-3'  value={loanty} onChange={(e)=>setloanty(e.target.value)}>
                            <option selected value="" >Select</option>
                            <option value='Daily'>Daily</option>
                            <option value='Monthly'>Monthly</option>
                            <option value='Quarterly'>Quarterly</option>
                            <option value='Annual'>Annual</option>
                    </select>

                    <label  className='lable' for='companyName'>Loan Purpose<samp className='text-danger'> * </samp></label>
                    <input type='text' className='form-control mb-3' value={loanpur} onChange={(e)=>setloanpur(e.target.value )} />


                    
                    <label  className='lable' for='companyName'>Collateral<samp className='text-danger'> * </samp></label>
                    <select required  className='form-control mb-3' value={Collateral} onChange={(e)=>setCollateral(e.target.value )} >
                            <option selected value="" >Select</option>
                            <option value='Cash Collateral'>Cash Collateral</option>
                            <option value='Other'>Other</option>
                    </select>
                      
                    {Collateral=='Other' ? 
                    <>
                    <label  className='lable' for='companyName'>Please Specify Collateral<samp className='text-danger'> * </samp></label>
                    <input type='text' className='form-control mb-3' value={Collateralty} onChange={(e)=>setCollateralty(e.target.value )} />
                    </>
                    : 
                   ''
                    }
                    
                </div>

                <div className="stepperSubDiv">

                  <h3 className='mb-5'>Guarantor Details</h3>

                  {MemberInfo &&

                  <>

                  <div style={{ width: 400 }}>
                      <ReactSearchAutocomplete
                      items={MemberInfo}
                      // fuseOptions={{ keys: ["trans_code", "tel"] }}
                      // resultStringKeyName="tel"
                      
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelectGua}
                      //onFocus={handleOnFocus}
                      onClear={clearg}
                      autoFocus
                      
                      formatResult={formatResult}
                      placeholder='Search Applicant Name'
                      
                      />
                    
                  </div>

                  </>

                  }
                  <div className='row mt-4'>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Name</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cname} onChange={(e)=>setCname(e.target.value)} />
                  </div>



                  <div className='col-md-6'>
                
                  <lable className='lable' for='Login Status'><samp className='text-danger'>* </samp> Gender</lable>
                              <select required  className='form-control' value={Cgender}  onChange={(e)=>setCgender(e.target.value)} >
                                  <option selected value="Select" >Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Famale">Famale</option>
                              </select>

                  </div>

                  </div>

                  <div className='row'>
                      
                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger'>* </samp>Date Of Birth</label>
                  <input name="Name"  required type="date" className="form-control mb-3 " value={Cdob} onChange={GcalAge} />
                  </div>



                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Age</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cage}  disabled />
                  </div>

                  </div>

                  <div className='row'>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Telephone</label>
                  <input name="number"  required type="text" className="form-control mb-3 " value={Ctel} onChange={(e)=>setCtel(e.target.value)}  />
                  </div>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Marital Status</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cmar} onChange={(e)=>setCmar(e.target.value)} />
                  </div>

                  </div>

                  <div className='row'>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Identification Type</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cid}  onChange={(e)=>setCid(e.target.value)} />
                  </div>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Identification Number</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cidn}  onChange={(e)=>setCidn(e.target.value)} />
                  </div>

                  </div>

                  <div className='row'>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Residential Address</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cres}  onChange={(e)=>setCres(e.target.value)} />
                  </div>

                  <div className='col-md-6'>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Nearest Landmark</label>
                  <input name="Name"  required type="text" className="form-control mb-3 " value={Cnland}  onChange={(e)=>setCnland(e.target.value)} />
                  </div>

                  </div>

                  
                  <div className=''>
                  <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Account Number</label>
                  <input name="number"  required type="number" className="form-control mb-3 " value={GaccNumber}  onChange={(e)=>setGaccNumber(e.target.value)} />
                  </div>


                </div>

                </Stepper>  
                   

                </Modal.Body>
                


        </Modal>
    </div>
  )
}

export default LoanApplication