import React  ,{useState ,useEffect }from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import Stepper from "awesome-react-stepper";
import { loadloanApricat } from '../actions/customerAccountAction';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CurrencyInput from 'react-currency-input-field';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { getCustomerDetailsAction } from '../actions/customerAccountAction';
import { cash ,cashGH ,numberFormat} from './NumberFormat';
import { customerAccountHistory } from '../actions/customerAccountAction';
import moment from 'moment'
import { ErrorMessage } from './Message';
import Loader from '../Components/Loader';
import { registerComittee } from '../actions/settingAction';
import { PRODUCT_REGISTRATION_RESET } from '../constants/settingConstants';
import { successMessage } from './Message';
import { loadCommitteComm} from '../actions/customerAccountAction';
import { updateSaveCommitteeUpdate ,updateLoanApproval } from '../actions/settingAction';
import Swal from 'sweetalert2'
import underReview from '../images/underReview.jpg'
import approve from '../images/approve.jpg'
import disapproved from '../images/disapproved.jpg'
import { VAULT_BALANCE_RESET } from '../constants/vaultConstants';


function LoanCommittee() {

  const dispatch=useDispatch()

   const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
   const { customerAccounts ,customerAccountsLoading }=CustomerAccountReducer


  const CustomerAccountTrancationReducer = useSelector(state => state. CustomerAccountTrancationReducer)
  const {Account_histoeyLoading ,Account_histoey }= CustomerAccountTrancationReducer
  
  const [AccountHistoey, setAccountHistoey]=useState('')

  const [loadloanApricatInfor ,setloadloanApricatInfor]=useState()

  const registerProductReducer = useSelector(state => state.registerProductReducer)
  const {registerProductLoading ,productSuccess  ,registerProductInfo ,error}=registerProductReducer

  const VaultBalanceReducer = useSelector(state => state.VaultBalanceReducer)
  const {VaultBalance ,VaultBalanceLoading }=VaultBalanceReducer
  
  const[LoanApproved ,setLoanApproved]=useState('')
  const [AmountApproved ,setAmountApproved]=useState('')
  const [PayableMonths ,setPayableMonths]=useState('')
  const [CashLien ,setCashLien]=useState('')
  const [BusinessSupport ,setBusinessSupport]=useState('')
  const [LoanProccssing ,setLoanProccssing]=useState('')
  const [InsuranceFee ,setInsuranceFee]=useState('')

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo }=userLogin

  const [opinion ,setopinion]=useState('')
  const [reason ,setreason]=useState('')

  const [dat_1 ,setdat1]=useState()

  const [dat_2 ,setdat2]=useState()


  const [Aname ,setAname]=useState('')
  const [Agender ,setAgender]=useState('')
  
  const [Adob ,setAdob]=useState('')
  const [Aage ,setAage]=useState('')
  
  const [Atel ,setAtel]=useState('')
  const [Amar ,setAmar]=useState('')
  
  const [Aid ,setAid]=useState('')
  const [Aidn ,setAidn]=useState('')
  
  const [Ares ,setAres]=useState('')
  const [Anland ,setAnland]=useState('')

  const [Acc,setAcc]=useState('')
  const [Accname ,setAccname]=useState('')
  const [pro,setpro]=useState('')
  const [Accty ,setAccty]=useState('')


  const [ty , setty]=useState('')
  const [loanLimit , setloanLimit]=useState('')
  const [Period , setPeriod]=useState('')
  const [rate , setrate]=useState('')
  const[amoreq ,setamoreq]=useState('0.00')
  const[loanty ,setloanty]=useState('')
  const[loanpur ,setloanpur]=useState('')
  const[Collateral ,setCollateral]=useState('')
  const[Collateralty ,setCollateralty]=useState('')


  const [Cname ,setCname]=useState('')
  const [Cgender ,setCgender]=useState('')
  
  const [Cdob ,setCdob]=useState('')
  const [Cage ,setCage]=useState('')
  
  const [Ctel ,setCtel]=useState('')
  const [Cmar ,setCmar]=useState('')
  
  const [Cid ,setCid]=useState('')
  const [Cidn ,setCidn]=useState('')
  
  const [Cres ,setCres]=useState('')
  const [Cnland ,setCnland]=useState('')


  useEffect(()=>{
  
     
       dispatch(loadloanApricat())
      
  
      },[dispatch])


  useEffect(()=>{
  
      setloadloanApricatInfor(customerAccounts)

      
  
    },[CustomerAccountReducer])

 

useEffect(()=>{

  setAccountHistoey(Account_histoey)

},[CustomerAccountTrancationReducer])

     const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
      }
      
      const handleOnHover = (result) => {
        // the item hovered
       // console.log(result)
      }


      const handleOnFocus = () => {
        //console.log('Focused')
      }
      

    const [selectedRow , setselectedRow]=useState('')

    const handleOnSelect = (row) => {
        
       try {
        setselectedRow(row)
        setAname(row.Aname)
        setAgender(row.Agender)
        setAdob(row.Adob)
        setAage(row.Aage)
        setAtel(row.Atel)
        setAmar(row.Amar)
        setAid(row.Aid)
        setAidn(row.Aidn)
        setAres(row.Ares)
        setAnland(row.Anland)

        setAcc(row.Acc)
        setAccname(row.Accname)
        setpro(row.pro)
        setAccty(row.Accty)

        setCname(row.Cname)
        setCgender(row.Cgender)
        setCdob(row.Cdob)
        setCage(row.Cage) 
        setCtel(row.Ctel)
        setCmar(row.Cmar)
        setCid(row.Cid)
        setCidn(row.Cidn)
        setCres(row.Cres)
        setCnland(row.Cnland)

        setty(row.ty)
        setloanLimit(row.loanLimit)
        setPeriod(row.Period)
        setrate(row.rate)
        setamoreq(row.amoreq)
        setloanty(row.loanty)
        setloanpur(row.loanpur)
        setCollateral(row.Collateral)
        setCollateralty(row.Collateralty)
        setLoanApproved(row.LoanApproved)
        setAmountApproved(row.AmountApproved)
        setBusinessSupport(row.BusinessSupport)
        setCashLien(row.CashLien)
        setInsuranceFee(row.InsuranceFee)
        setLoanApproved(row.LoanApproved)
        setLoanProccssing(row.LoanProccssing)
        setPayableMonths(row.PayableMonths)
         //dispatch(getCustomerDetailsAction(row.Acc))

         dispatch(loadCommitteComm(row.trans_code))
      
       } catch (error) {
        
       }
       
      }

      const [getcommitCo ,setgetcommitCo]=useState('')

      useEffect(()=>{

        if(selectedRow){

        const getcommitCo=VaultBalance.find(obj=>obj.name==userInfo.username && obj.code==selectedRow.trans_code)
        if(getcommitCo){

        setopinion(getcommitCo.opinion)
        setreason(getcommitCo.reason)

        setgetcommitCo(getcommitCo)

        }
      }

      },[VaultBalanceReducer])


      const formatResult = (loanapplication) => {
        return (
          <>
      
            <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {loanapplication.Aname} </span>
            <span style={{ display: 'block', textAlign: 'left', paddingBottom:'5px'}}> {loanapplication.Atel} </span>
            
          </>
        )
      }


  const [showHistory ,setshowHistory]=useState()
  
    const openShowHistory =()=>{

    
      if(Aname==''){

      }else {

        setaprican(true)
        dispatch(customerAccountHistory(selectedRow.Acc))
      setshowHistory(true)

      }
       
    }

    const openGuarHistory =()=>{

      if(Aname==''){

      }else {

      setaprican(false)
      dispatch(customerAccountHistory(selectedRow.GaccNumber))
      setshowHistory(true)

      }
       
    }
  
    const closeShowHistory =()=>{
      setshowHistory(false)
    }

   const historyColumns =[
   
      {
       name:'#' ,
       selector:row=>row.trans_code 
       ,wrap:true 
    
      } ,
    
       {
        name:'Date' ,
        selector:row=>row.dat
        ,wrap:true
       },
    
       {
        name:'Deposit',
        selector:row=> cash(row.cr) 
        ,wrap:true ,
        sortable:true
       } ,
    
       {
        name:'Withdrawal',
        selector:row=> cash(row.dr) 
        ,wrap:true ,
        sortable:true
       },
    
       {
        name:'Transaction By',
        selector:row=> row.trans_by 
        ,wrap:true ,
        sortable:true
       },
    
       {
        name:'Tel#',
        selector:row=> row.tel 
        ,wrap:true ,
        sortable:true
       },
     
       {
        name:'User',
        selector:row=> row.user 
        ,wrap:true ,
        sortable:true
       },
    
      
    ]


    const commColumns =[
   
      {
       name:'Member' ,
       selector:row=>row.name 
       ,wrap:true 
    
      } ,
    
       {
        name:'Approval' ,
        selector:row=>row.opinion
        ,wrap:true
       },
    
       {
        name:'Reason ',
        selector:row=> row.reason
        ,wrap:true ,
        sortable:true
       } 
    
       
    
      
    ]

    const searchbydepositDate=()=>{


    let dat1 = moment(dat_1).format('YYYY-MM-DD')

    let dat2 = moment(dat_2).format('YYYY-MM-DD')

    setAccountHistoey(Account_histoey.filter(obj=>obj.dat2 >=dat1 && obj.dat2<=dat2))
     
   }


  const [aprican , setaprican]=useState(true)
  let acc_num=''

  const Accstatement=()=>{
    
    if(aprican){
      acc_num= Acc
    }else {
      acc_num= selectedRow.GaccNumber
    }

    if(dat_1=='' || dat_2==''){
      ErrorMessage('Please Select Statement Period')
    }else {

      let dat1 = moment(dat_1).format('YYYY-MM-DD')

      let dat2 = moment(dat_2).format('YYYY-MM-DD')

      setAccountHistoey(Account_histoey.filter(obj=>obj.dat2 >=dat1 && obj.dat2<=dat2))

      //Calculate balance bf

      let stadat='1991-04-04' 
      let bfdate = moment(dat_1).subtract(1, "days").format("YYYY-MM-DD");
      console.log(bfdate)

      let bfdata=Account_histoey.filter(obj=>obj.dat2 >=stadat && obj.dat2<=bfdate)

      //console.log(bfdata)

    var  bfamount = 0;
    for(let i = 0; i < bfdata.length; i++){
      bfamount += bfdata[i].cr - bfdata[i].dr
     }
  
  
    
     let Account_ty= pro
     let bal_bf= bfamount

     window.open(`http://localhost/report/Account_Statement.html?dat_1=${dat_1}&dat_2=${dat_2}&acc_num=${acc_num}&Account_ty=${Account_ty}&bal_bf=${bal_bf}`, "_blank");
      
    }

  }  

const [whichLoan , setwhichLoan]=useState(0)

const saveCommittee=()=>{

    if(Aname==''){
     
      ErrorMessage('No Loan Applicant Information')

    }else if(opinion==''|| reason=='' ){

      ErrorMessage('No Member Opinion / Decision Found')

    }else {

      let dat_2 = moment().format('YYYY-MM-DD')
      let dat=moment().format('MMMM Do YYYY, h:mm:ss a')

      if(getcommitCo){

        let applicant_name = Aname
        let name=userInfo.username
        dispatch(updateSaveCommitteeUpdate({trans_code:getcommitCo.trans_code , name ,opinion ,reason ,dat ,dat_2 ,applicant_name}))

      }else {

        dispatch(registerComittee(userInfo.username , opinion , reason ,dat , dat_2 ,selectedRow.trans_code ,Aname))

      }

      setwhichLoan(1)
      
    }
   
}


  useEffect(()=>{

     if(productSuccess && whichLoan==1){
    
          successMessage('Member Opinion / Decision Successfully Saved')
         // clear()
          dispatch({type:PRODUCT_REGISTRATION_RESET})

          dispatch(loadCommitteComm(selectedRow.trans_code))
          setwhichLoan(0)
        //  dispatch(loadloanApricat())
        }else if(productSuccess && whichLoan==2) {

          successMessage('Loan Committee Approval Successfully Saved')
          dispatch({type:PRODUCT_REGISTRATION_RESET})

          dispatch(loadCommitteComm(selectedRow.trans_code))
          setwhichLoan(0)
        }

        clear();
        dispatch(loadloanApricat())
      
      
  },[registerProductReducer])



 const loanApproval=()=>{

  if(LoanApproved==''){
    ErrorMessage('Is The Loan Approved ?')
  }else {

    if(LoanApproved=='Yes'){

      if(AmountApproved==''){
        ErrorMessage('Please Enter The Loan Amount Approved')
     
      }else if(PayableMonths==''){
        ErrorMessage('Enter Payable Within(Months)')
       
      }else if(CashLien==''){
        ErrorMessage('Enter Cash Lien (GHS)')
       
      }else if(BusinessSupport==''){
        ErrorMessage('Enter Business Support Fee (GHS)')

      }else if(LoanProccssing==''){
        ErrorMessage('Enter Loan Proccssing Fee (GHS)')
        
      }else if(InsuranceFee==''){
        ErrorMessage('Insurance Fee (GHS)')
      }else {

            const swalWithBootstrapButtons = Swal.mixin({
                  customClass: {
                  confirmButton: "btn btn-success mx-4",
                  cancelButton: "btn btn-danger"
                  },
                  buttonsStyling: false
              });
              swalWithBootstrapButtons.fire({
                  title: "Confirm",
                  text: "Are You Sure You Want to Approve The Selected Applicant Loan ?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
               
            
              }).then((result) => {
            
                  if (result.isConfirmed) {

                    let Status=2
            
                   dispatch(updateLoanApproval({trans_code:selectedRow.trans_code , LoanApproved ,AmountApproved ,PayableMonths ,CashLien ,BusinessSupport ,LoanProccssing ,InsuranceFee ,Status}))
              
                
                 
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
            
                 
                  }
                  
              });

      }

    }else if(LoanApproved=='No'){

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Confirm",
        text: "Are You Sure You Want to Reject The Selected Applicant Loan ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
     
  
    }).then((result) => {
  
        if (result.isConfirmed) {

          let Status=3
         
          let AmountApproved=''
          let PayableMonths=''
          let CashLien=''
          let BusinessSupport=''
          let LoanProccssing=''
          let InsuranceFee=''
          
  
         dispatch(updateLoanApproval({trans_code:selectedRow.trans_code , LoanApproved ,AmountApproved ,PayableMonths ,CashLien ,BusinessSupport ,LoanProccssing ,InsuranceFee ,Status}))
    
      
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
  
       
        }
        
    });


    }

    setwhichLoan(2)

  }

 } 

 const clear=()=>{

        setselectedRow('')
        setAname('')
        setAgender('')
        setAdob('')
        setAage('')
        setAtel('')
        setAmar('')
        setAid('')
        setAidn('')
        setAres('')
        setAnland('')

        setAcc('')
        setAccname('')
        setpro('')
        setAccty('')

        setCname('')
        setCgender('')
        setCdob('')
        setCage('') 
        setCtel('')
        setCmar('')
        setCid('')
        setCidn('')
        setCres('')
        setCnland('')

        setty('')
        setloanLimit('')
        setPeriod('')
        setrate('')
        setamoreq('')
        setloanty('')
        setloanpur('')
        setCollateral('')
        setCollateralty('')
        setLoanApproved('')
        setAmountApproved('')
        setBusinessSupport('')
        setCashLien('')
        setInsuranceFee('')
        setLoanApproved('')
        setLoanProccssing('')
        setPayableMonths('')
        setopinion('')
        setreason()
        dispatch({type:VAULT_BALANCE_RESET})
        
 }

  return (
    <div>
       <h3>Loan Committee</h3>
       <hr/>

       <div className='solcon overflow-auto vh-100' >
        <div className=''>

       
       <div className='card'>

        <div className='card-body'>

     
         
       <Stepper
          strokeColor="#00000"
          fillStroke="#012970"
          activeColor="#06A3DA"
          activeProgressBorder="2px solid #06A3DA"
          defaultActiveStep='true'

          submitBtn={<button className="btn-class page1">Save</button>}
          continueBtn={<button className="show_info_btn page1">Next Page</button>}
          backBtn={<button className="show_info_btn">Previous Page</button>}

          onSubmit={registerProductLoading ? '' :  (step) => saveCommittee()}
          >
      
          <div className="stepperSubDiv">


          {loadloanApricatInfor &&
        
        <>

          <div style={{ width: 400 }} className='mb-3'>
            <ReactSearchAutocomplete
            items={loadloanApricatInfor}
            fuseOptions={{ keys: ["Aname", "Atel"] }}
            resultStringKeyName="Aname"
            
             onSearch={handleOnSearch}
             onHover={handleOnHover}
             onSelect={handleOnSelect}
             onFocus={handleOnFocus}
             onClear={clear}
            autoFocus
            
            formatResult={formatResult}
            placeholder='Search Applicant Name'
            
            />
            
        </div>
        
        </>
        
          }

              <div className='row'>
              <div className='col-md-4'>

              {customerAccountsLoading && <Loader/>}
                
            
              <h3>1.Applicant Information</h3>
              
              <div className='row'>

              <div className='col-md-6'>
              <label className='lable' for='Surname'>Name</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Aname} disabled   />
              </div>



              <div className='col-md-6'>

              <lable className='lable' for='Login Status'> Gender</lable>
                        <select required  className='form-control' value={Agender}  disabled >
                            <option selected value="Select" >Select</option>
                            <option value="Male">Male</option>
                            <option value="Famale">Famale</option>
                        </select>

              </div>

              </div>

          <div className='row'>
              
          <div className='col-md-6'>
          <label className='lable' for='Surname'>Date Of Birth</label>
          <input name="Name"  required type="date" className="form-control mb-3 " value={Adob}  disabled />
          </div>



          <div className='col-md-6'>
          <label className='lable' for='Surname'>Age</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Aage}  disabled />
          </div>

          </div>

          <div className='row'>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Telephone</label>
          <input name="Name"  required type="number" className="form-control mb-3 " value={Atel} disabled  />
          </div>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Marital Status</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Amar}  disabled />
          </div>

          </div>

          <div className='row'>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Identification Type</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Aid} disabled  />
          </div>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Identification Number</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Aidn} disabled  />
          </div>

          </div>

          <div className='row'>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Residential Address</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Ares}  disabled />
          </div>

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Nearest Landmark</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Anland}  disabled />
          </div>

          </div>

          <h3>2.Account Infomation</h3>
          
          <div className='row'> 

          <div className='col-md-6'>
          <label className='lable' for='Surname'>Account Number</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Acc}  disabled />

          
          <label className='lable' for='Surname'>Account Name</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={Accname}  disabled />
          </div>

          <div className='col-md-6'>

          <label className='lable' for='Surname'>Product Type</label>
          <input name="Name"  required type="text" className="form-control mb-3 " value={pro}  disabled />

          <label className='lable' for='Surname'>Account Type</label>
                <input name="Name"  required type="text" className="form-control mb-3 " value={Accty}  disabled />

          </div>
          </div>
              </div>

              <div className='col-md-4'>

              <h3>3. Details Of Loan Request</h3>

              
              <label className='lable' for='Surname'>Account Type</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={ty}  disabled />
              
             
             
                <div className='row'>
                <div className='col-md-4 sm-3'>
                <label  className='lable' for='companyName'> Loan Amount Limit</label>
                <CurrencyInput
                id="input-example"
                name="input-name"
                //placeholder="Please enter a number"
                defaultValue={0}
                decimalsLimit={2}
                className='form-control mb-3'
                value={loanLimit}
                decimalScale={2}
                disabled
                />
                </div>
                <div className='col-md-4 sm-3'>
                <label  className='lable' for='companyName'>Period(In Months)</label>
                <input type='text' className='form-control mb-3' value={Period}  disabled />
                </div>

                <div className='col-md-4 sm-3'>
                <label  className='lable' for='companyName'>  Interest rates(%)</label>
                <input type='text' className='form-control mb-3' value={rate}  disabled />
                </div>
                </div>

                <label  className='lable' for='companyName'> Amount required</label>
                <CurrencyInput
                id="input-example"
                name="input-name"
                //placeholder="Please enter a number"
                defaultValue={0}
                decimalsLimit={2}
                className='form-control mb-3'
                value={amoreq}
                decimalScale={2}
                disabled
                />

                <div className='row'>
                  <div className='col-md-6'>
                  <label  className='lable' for='companyName'>Mode Of Payment</label>
                  <input type='text' className='form-control mb-3' value={loanty} disabled  />
                  </div>

                  <div className='col-md-6'>
                  <label  className='lable' for='companyName'>Loan Purpose</label>
                  <input type='text' className='form-control mb-3' value={loanpur} disabled />
                  </div>
                </div>  
               
                
                <div className='row'>
                  <div className='col-md-6'>
                  <label  className='lable' for='companyName'>Collateral</label>
                  <input type='text' className='form-control mb-3' value={Collateral} disabled />
                  </div>
                  <div className='col-md-6'>
                  <label  className='lable' for='companyName'>Collateral Type</label>
                  <input type='text' className='form-control mb-3' value={Collateralty} disabled />
                  </div>
                </div>
               
                <h3>4. Applicant History </h3>

               
               
                
                <button onClick={openShowHistory}  className='show_info_btn mt-4  w-100'>Deposit / Withdrawal History</button>
               
                <button  className='show_info_btn mt-4  w-100'>Previous Credit Infomation</button>
             
              
              </div>

              <div className='col-md-4'>

              <h3>5. Guarantor Details</h3>

              <div className='row'>
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Name</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cname} disabled />
              </div>

              <div className='col-md-6'>
              <label className='lable' for='Surname'>Gender</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cgender} disabled  />
              </div>
              </div>
              

              <div className='row'>
             
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Date Of Birth</label>
              <input name="Name"  required type="date" className="form-control mb-3 " value={Cdob} disabled />
              </div>
              <div className='col-md-6'>
              <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Age</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cage}  disabled />
              </div>

             
              </div>
             
              <div className='row'>
              <div className='col-md-6'>
              <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Telephone</label>
              <input name="number"  required type="text" className="form-control mb-3 " value={Ctel} disabled />
              </div>
              <div className='col-md-6'>
              <label className='lable' for='Surname'><samp className='text-danger mt-2'>* </samp>Marital Status</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cmar} disabled />
              </div>
              </div>
            
              <div className='row'>
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Identification Type</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cid} disabled  />
              </div>
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Identification Number</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cidn}  disabled />
              </div>
              </div>
              
              <div className='row'>
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Residential Address</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cres} disabled  />
              </div>
              <div className='col-md-6'>
              <label className='lable' for='Surname'>Nearest Landmark</label>
              <input name="Name"  required type="text" className="form-control mb-3 " value={Cnland}  disabled />
              </div>
              </div>

              <h3>6. Guarantor History </h3>

               
                <button onClick={openGuarHistory}  className='show_info_btn mt-4  w-100'>Deposit / Withdrawal History</button>
               
                <button  className='show_info_btn mt-4  w-100'>Previous Credit Infomation</button>

             
              </div>
          </div>
          </div>

          <div className="stepperSubDiv">
           

            <div className='row'>
            <div className='col-md-6 col-sm-6'>
            <h3 className='mb-4'> Member Opinion / Decision</h3>
            <hr/>

            <strong  for='Login Status'><samp className='text-danger'>* </samp> Do You Consider The Applicant Is Credit Worthy And For That Matter The Request Should Be Approved?</strong>
            
              <select required  className='form-control mb-4' value={opinion} onChange={(e)=>setopinion(e.target.value)}  >
                  <option selected value="Select" >Select</option>
                  <option value="Yes">YES</option>
                  <option value="No">NO</option>
              </select>


              <strong  for='Surname'><samp className='text-danger '>* </samp>State Reason For Your Decision</strong>
              <input name="Name"  required type="text" className="form-control mb-3 " value={reason} onChange={(e)=>setreason(e.target.value)}/>

              {VaultBalance.length >=3 ?
              
              <>
              
              <div className='card'>
              <div className='card-body'>
              <h3 className='mb-4'>Loan Committee Approval <img width={70} height={70} src={selectedRow.Status ==1 ? underReview : selectedRow.Status ==2 ? approve :  selectedRow.Status ==3 ? disapproved :'' } className="rounded-circle"/></h3>
              <hr/>
              
              <strong  for='Login Status'><samp className='text-danger'>* </samp>Is The Loan Approved</strong>
            
            <select required  className='form-control mb-4' value={LoanApproved} onChange={(e)=>setLoanApproved(e.target.value)} >
                <option selected value="Select" >Select</option>
                <option value="Yes">YES</option>
                <option value="No">NO</option>
            </select>

            
            {LoanApproved=='Yes' ?
            
            <>
             <div className='row'>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Amount Approved (GHS)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={AmountApproved} onChange={(e)=>setAmountApproved(e.target.value)} />
            </div>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Payable Within(Months)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={PayableMonths} onChange={(e)=>setPayableMonths(e.target.value)}/>
            </div>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Cash Lien (GHS)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={CashLien} onChange={(e)=>setCashLien(e.target.value)}/>
            </div>
            </div>


            <div className='row'>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Business Support Fee (GHS)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={BusinessSupport} onChange={(e)=>setBusinessSupport(e.target.value)} />
            </div>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Loan Proccssing Fee (GHS)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={LoanProccssing} onChange={(e)=>setLoanProccssing(e.target.value)}/>
            </div>
            <div className='col-md-4'>
            <strong  for='Surname'><samp className='text-danger '>* </samp>Insurance Fee (GHS)</strong>
            <input name="number"  required type="number" className="form-control mb-3" value={InsuranceFee} onChange={(e)=>setInsuranceFee(e.target.value)}/>
            </div>
            </div>

            </>
            :''}
           

            <button onClick={loanApproval} className='btn-class w-100'>Save</button>
              

              </div>
              </div>
              
              </> :''
              
                
              
              }
            

            </div>
            <div className='col-md-6 col-sm-6'>

            <h3 className='mb-4'> Member Opinion / Decision History </h3>
            <hr/>

            <div className='card'>
            <div className='card'>
                      <DataTable className='rdt_Table' columns={commColumns}  data={VaultBalance=='' ? VaultBalance : VaultBalance}   
                        selectableRowsHighlight 
                        highlightOnHover
                        fixedHeader
                        pagination 
                        paginationPerPage={4}
                          //onSelectedRowsChange={getSelectedSubjects}
                        // selectableRows  
                          //selectableRowSelected={getPatientInfo} 
                          //</div> onRowClicked={getPatientInfo}
                        responsive={true}
                          >
          
                      </DataTable>
                      </div>
            </div>

            </div>
            </div>
            



           </div>
                            
         </Stepper>  


       </div>
       </div>
    </div>
    </div>

    <Modal size="lg" show={showHistory} onHide={closeShowHistory} dialogClassName="my-modal"  backdrop="static" keyboard={false}>                                                                                                                                 
            <Modal.Header closeButton>
            <Modal.Title>Account History</Modal.Title>
            </Modal.Header>
            <Modal.Body  >

            {Account_histoeyLoading && <Loader/>}

            <div className='card'>
              <div className='card-header userbal d-flex flex-column align-items-center'>Customer Account Transaction</div>

              <div className='card-body ms-4 me-4'>
              <div className="card-body">
                     
                     <div className='row'>

                      <div className="col-md-2 col-lg-2">
                        <strong className='' for='Surname'>1st Period</strong>
                        <input name="number"  type="date" className="form-control" value={dat_1} onChange={(e)=>setdat1(e.target.value)}  />
                      </div>

                      <div className="col-md-2 col-lg-2">
                        <strong className='' for='Surname'>2nd Period</strong>
                        <input name="number"  type="date" className="form-control" value={dat_2} onChange={(e)=>setdat2(e.target.value)} />
                      </div>

                      <div className="col-md-2 col-lg-2">
                       
                        <button onClick={searchbydepositDate} className=' mt-3 show_info_btn  w-100'>Search Transaction</button>
                        
                      </div>

                      <div className="col-md-2 col-lg-2">
                       
                        <button onClick={Accstatement} className=' mt-3 show_info_btn  w-100'>Account Statement</button>
                        
                      </div>

                      </div>

                      <hr/>

                      <div className='card'>
                      <DataTable className='rdt_Table' columns={historyColumns}  data={AccountHistoey=='' ? Account_histoey : AccountHistoey}   
                        selectableRowsHighlight 
                        highlightOnHover
                        fixedHeader
                        pagination 
                        paginationPerPage={8}
                          //onSelectedRowsChange={getSelectedSubjects}
                        // selectableRows  
                          //selectableRowSelected={getPatientInfo} 
                          //</div> onRowClicked={getPatientInfo}
                        responsive={true}
                          >
          
                      </DataTable>
                      </div>
                                            
                    </div>
              </div>
              </div>

            
            </Modal.Body>
                        
    </Modal>

    </div>
  )
}

export default LoanCommittee