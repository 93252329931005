import { CREATE_ACCOUNT_REQUEST,CREATE_ACCOUNT_SUCCESS,CREATE_ACCOUNT_FAIL,CREATE_ACCOUNT_RESET ,

    CUSTOMER_ACCOUNTS_REQUEST ,CUSTOMER_ACCOUNTS_SUCCESS ,CUSTOMER_ACCOUNTS_FAIL ,CUSTOMER_ACCOUNTS_RESET,

    CUSTOMER_ACCOUNTS_REQUEST_DETAILS,CUSTOMER_ACCOUNTS_SUCCESS_DETAILS,CUSTOMER_ACCOUNTS_FAIL_DETAILS,CUSTOMER_ACCOUNTS_RESET_DETAILS ,

    CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS ,CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS ,CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS ,CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS ,

    CUSTOMER_ACCOUNTS_NUMBER_REQUEST ,CUSTOMER_ACCOUNTS_NUMBER_SUCCESS ,CUSTOMER_ACCOUNTS_NUMBER_FAIL  ,CUSTOMER_ACCOUNTS_NUMBER_RESET ,

    SAVE_AUTO_REQUEST,SAVE_AUTO_SUCCESS ,SAVE_AUTO_FAIL,SAVE_AUTO_RESET ,

    LOAD_AUTO_REQUEST ,LOAD_AUTO_SUCCESS ,LOAD_AUTO_FAIL,LOAD_AUTO_RESET ,

    DEPOSIT_REQUEST,DEPOSIT_SUCCESS,DEPOSIT_FAIL,DEPOSIT_RESET ,

    ACCOUNT_TRANSACTION_REQUEST , ACCOUNT_TRANSACTION_SUCCESS ,ACCOUNT_TRANSACTION_FAIL ,ACCOUNT_TRANSACTION_RESET ,

    ACCOUNT_BALANCE_REQUEST ,ACCOUNT_BALANCE_SUCCESS ,ACCOUNT_BALANCE_FAIL ,ACCOUNT_BALANCE_RESET ,

    USER_DEPOSIT_TOTAL_REQUEST ,USER_DEPOSIT_TOTAL_SUCCESS ,USER_DEPOSIT_TOTAL_FAIL ,USER_DEPOSIT_TOTAL_RESET ,

    CREATE_CORACCOUNT_REQUEST ,CREATE_CORACCOUNT_SUCCESS ,CREATE_CORACCOUNT_RESET ,CREATE_CORACCOUNT_FAIL ,

    CREATE_SIGNATORY_REQUEST ,CREATE_SIGNATORY_SUCCESS,CREATE_SIGNATORY_FAIL ,CREATE_SIGNATORY_RESET ,

    DETAILS_CORACCOUNT_REQUEST ,DETAILS_CORACCOUNT_SUCCESS ,DETAILS_CORACCOUNT_FAIL,DETAILS_CORACCOUNT_RESET ,

    DETAILS_SIGNATORY_REQUEST ,DETAILS_SIGNATORY_SUCCESS ,DETAILS_SIGNATORY_FAIL ,DETAILS_SIGNATORY_RESET ,

    ACCOUNT_AUTO_SERVICE_REQUEST ,ACCOUNT_AUTO_SERVICE_SUCCESS ,ACCOUNT_AUTO_SERVICE_FAIL ,ACCOUNT_AUTO_SERVICE_RESET ,

    ACCOUNT_HISTORY_REQUEST ,ACCOUNT_HISTORY_SUCCESS ,ACCOUNT_HISTORY_FAIL ,ACCOUNT_HISTORY_RESET ,

    MOB_ACCOUNT_TRANSACTION_REQUEST,MOB_ACCOUNT_TRANSACTION_SUCCESS ,MOB_ACCOUNT_TRANSACTION_FAIL,MOB_ACCOUNT_TRANSACTION_RESET ,

    MOB_ACCOUNT_TRANSACTION_UPDATE_REQUEST ,MOB_ACCOUNT_TRANSACTION_UPDATE_SUCCESS ,MOB_ACCOUNT_TRANSACTION_UPDATE_FAIL ,MOB_ACCOUNT_TRANSACTION_UPDATE_RESET


 } from "../constants/customerAccountConstants";

 
 
export const customerAccountUpdateReducer=(state={},action)=>{

    switch(action.type){
        case CREATE_ACCOUNT_REQUEST :
            return{customerAccountUpdateLoading:true  }

        case CREATE_ACCOUNT_SUCCESS :
            return{customerAccountUpdateLoading:false ,  accountInfo:action.payload}  

        case CREATE_ACCOUNT_FAIL :
            return {customerAccountUpdateLoading:false , error:action.payload}

        case CREATE_ACCOUNT_RESET:
            return{}
        
        default:
            return state

    }

}

export const CustomerAccountReducer=(state={customerAccounts:[]},action)=>{

    switch(action.type){

        case CUSTOMER_ACCOUNTS_REQUEST :
            return{customerAccountsLoading:true}

        case CUSTOMER_ACCOUNTS_SUCCESS :
            return{customerAccountsLoading:false , customerAccounts:action.payload}  

        case CUSTOMER_ACCOUNTS_FAIL :
            return {customerAccountsLoading:false , error:action.payload}

        case CUSTOMER_ACCOUNTS_RESET:
            return{customerAccounts:{}}
        
        default:
            return state

    }

} 


export const createBankAccountReducer=(state={},action)=>{

    switch(action.type){
        case CREATE_ACCOUNT_REQUEST :
            return{customerAccountUpdateLoading:true  }

        case CREATE_ACCOUNT_SUCCESS :
            return{customerAccountUpdateLoading:false , creataccout:true, accountInfo:action.payload}  

        case CREATE_ACCOUNT_FAIL :
            return {customerAccountUpdateLoading:false ,coraccsufferror:true , error:action.payload}

        case CREATE_ACCOUNT_RESET:
            return{}
        
        default:
            return state

    }

}


export const CustomerAccountDetailsReducer=(state={CustomerAccountDetails:[]},action)=>{

    switch(action.type){
        case CUSTOMER_ACCOUNTS_REQUEST_DETAILS :
            return{CustomerAccountDetailsLoading:true ,...state }

        case CUSTOMER_ACCOUNTS_SUCCESS_DETAILS :
            return{CustomerAccountDetailsLoading:false , loadsu:true , CustomerAccountDetails:action.payload}  

        case CUSTOMER_ACCOUNTS_FAIL_DETAILS :
            return {memberDetailsLoading:false , error:action.payload}

        case CUSTOMER_ACCOUNTS_RESET_DETAILS:
            return{CustomerAccountDetails:[]}
        
        default:
            return state

    }

}



export const CustomerAccountTypeDetailsReducer=(state={AccountTypeDetails:{}},action)=>{

    switch(action.type){
        case CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS :
            return{AccountTypeDetailsLoading:true ,...state  }

        case CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS :
            return{AccountTypeDetailsLoading:false , AccountTypeDetails:action.payload}  

        case CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS :
            return {AccountTypeDetailsLoading:false , error:action.payload}

        case CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS:
            return{AccountTypeDetails:{}}
        
        default:
            return state

    }

}


export const CustomerAccountNumberReducer=(state={accountNumber:[]},action)=>{

    switch(action.type){
        case CUSTOMER_ACCOUNTS_NUMBER_REQUEST :
            return{AccountNumberLoading:true }

        case CUSTOMER_ACCOUNTS_NUMBER_SUCCESS :
            return{AccountNumberLoading:false , accountNumber:action.payload}  

        case CUSTOMER_ACCOUNTS_NUMBER_FAIL :
            return {AccountNumberLoading:false , error:action.payload}

        case CUSTOMER_ACCOUNTS_NUMBER_RESET:
            return{accountNumber:{}}
        
        default:
            return state

    }

}


export const saveAutoService=(state={},action)=>{

    switch(action.type){
        case SAVE_AUTO_REQUEST :
            return{autoSaveLoading:true  }

        case SAVE_AUTO_SUCCESS :
            return{autoSaveLoading:false ,  autoSave:action.payload}  

        case SAVE_AUTO_FAIL :
            return {autoSaveLoading:false , error:action.payload}

        case SAVE_AUTO_RESET:
            return{}
        
        default:
            return state

    }

}


export const CustomerAutoServiceReducer=(state={autoServices:[]},action)=>{

    switch(action.type){
        case LOAD_AUTO_REQUEST:
            return{autoServicesLoading:true ,...state}

        case LOAD_AUTO_SUCCESS:
            return{autoServicesLoading:false , autoServices:action.payload}  

        case LOAD_AUTO_FAIL:
            return {autoServicesLoading:false , error:action.payload}

        case LOAD_AUTO_RESET:
            return{autoServices:[]}
        
        default:
            return state

    }

}


export const DepositReducer=(state={},action)=>{

    switch(action.type){
        case DEPOSIT_REQUEST :
            return{depositLoading:true }

        case DEPOSIT_SUCCESS :
            return{depositLoading:false , depositInfo:action.payload}  

        case DEPOSIT_FAIL :
            return {depositLoading:false , error:action.payload}

        case DEPOSIT_RESET:
            return{}
        
        default:
            return state

    }

}



export const accountTransactionReducer=(state={},action)=>{

    switch(action.type){
        case ACCOUNT_TRANSACTION_REQUEST :
            return{transactionLoading:true }

        case ACCOUNT_TRANSACTION_SUCCESS :
            return{transactionLoading:false , depositsu:true ,transactionInfo:action.payload}  

        case ACCOUNT_TRANSACTION_FAIL :
            return {transactionLoading:false , error:action.payload}

        case ACCOUNT_TRANSACTION_RESET:
            return{}
            
        default:
            return state  

    }

}




export const CustomerAccountBalanceReducer=(state={accountbalance:[]},action)=>{

    switch(action.type){
        case ACCOUNT_BALANCE_REQUEST :
            return{accountbalanceLoading:true ,...state}

        case ACCOUNT_BALANCE_SUCCESS :
            return{accountbalanceLoading:false , accountbalance:action.payload}  

        case ACCOUNT_BALANCE_FAIL :
            return {accountbalanceLoading:false , error:action.payload}

        case ACCOUNT_BALANCE_RESET:
            return{accountbalance:[]}
        
        default:
            return state

    }

}

export const USERAccountBalanceReducer=(state={userbalance:[]},action)=>{

    switch(action.type){
        case USER_DEPOSIT_TOTAL_REQUEST :
            return{userbalanceLoading:true ,...state}

        case USER_DEPOSIT_TOTAL_SUCCESS :
            return{userbalanceLoading:false , userbalance:action.payload}  

        case USER_DEPOSIT_TOTAL_FAIL :
            return {Loading:false , error:action.payload}

        case USER_DEPOSIT_TOTAL_RESET:
            return{userbalance:[]}
        
        default:
            return state

    }

}


export const createCorAccountReducer=(state={},action)=>{

    switch(action.type){
        case CREATE_CORACCOUNT_REQUEST :
            return{coraccLoading:true  }

        case CREATE_CORACCOUNT_SUCCESS :
            return{coraccLoading:false ,  coraccsuff: true ,coracc:action.payload}  

        case CREATE_CORACCOUNT_FAIL  :
            return {coraccLoading:false , error:action.payload}

        case CREATE_CORACCOUNT_RESET:
            return{}
        
        default:
            return state

    }

}

export const createsignatoryReducer=(state={},action)=>{

    switch(action.type){
        case CREATE_SIGNATORY_REQUEST :
            return{coraccLoading:true  }

        case CREATE_SIGNATORY_SUCCESS :
            return{SignatoryLoading:false ,  Signatory: true ,coracc:action.payload}  

        case CREATE_SIGNATORY_FAIL  :
            return {SignatoryLoading:false , error:action.payload}

        case CREATE_SIGNATORY_RESET:
            return{}
        
        default:
            return state

    }

}


export const CorporateAccountDetailsReducer=(state={CorporateAccountDetails:{}},action)=>{

    switch(action.type){
        case DETAILS_CORACCOUNT_REQUEST :
            return{CorporateAccountDetailsLoading:true ,...state}

        case DETAILS_CORACCOUNT_SUCCESS :
            return{CorporateAccountDetailsLoading:false ,  CorporateAccountDetails:action.payload}  

        case DETAILS_CORACCOUNT_FAIL  :
            return {CorporateAccountDetailsLoading:false , error:action.payload ,CorporateAccountDetails:{}}

        case DETAILS_CORACCOUNT_RESET:
            return{}
        
        default:
            return state

    }

}


export const CorporateAccountsignatoryDetailsReducer=(state={CorporateAccountsignatoryDetails:{}},action)=>{

    switch(action.type){
        case DETAILS_SIGNATORY_REQUEST :
            return{CorporateAccountsignatoryDetailsLoading:true ,...state }

        case DETAILS_SIGNATORY_SUCCESS :
            return{CorporateAccountsignatoryDetailsLoading:false ,  CorporateAccountsignatoryDetails:action.payload}  

        case DETAILS_SIGNATORY_FAIL  :
            return {CorporateAccountsignatoryDetailsLoading:false , error:action.payload ,CorporateAccountsignatoryDetails:{}}

        case DETAILS_SIGNATORY_RESET:
            return{}
        
        default:
            return state

    }

}


export const accountAutoServiceReducer=(state={accountAutoServicer:[]},action)=>{

    switch(action.type){
        case ACCOUNT_AUTO_SERVICE_REQUEST :
            return{accountAutoServicerLoading:true ,...state}

        case ACCOUNT_AUTO_SERVICE_SUCCESS :
            return{accountAutoServicerLoading:false , accountAutoServicer:action.payload}  

        case ACCOUNT_AUTO_SERVICE_FAIL :
            return {accountAutoServicerLoading:false , error:action.payload}

        case ACCOUNT_AUTO_SERVICE_RESET:
            return{accountAutoServicer:[]}
        
        default:
            return state

    }

}


export const CustomerAccountTrancationReducer=(state={Account_histoey:[]},action)=>{

    switch(action.type){

        case ACCOUNT_HISTORY_REQUEST :
            return{Account_histoeyLoading:true ,...state }

        case ACCOUNT_HISTORY_SUCCESS :
            return{Account_histoeyLoading:false , Account_histoey:action.payload}  

        case ACCOUNT_HISTORY_FAIL :
            return {Account_histoeyLoading:false , Account_histoeyerror:action.payload}

        case ACCOUNT_HISTORY_RESET:
            return{Account_histoey:{}}
        
        default:
            return state

    }

} 


export const MOBAccountTransactionReducer=(state={MOBAccount:[]},action)=>{

    switch(action.type){
        case MOB_ACCOUNT_TRANSACTION_REQUEST :
            return{MOBAccountLoading:true ,...state }

        case MOB_ACCOUNT_TRANSACTION_SUCCESS :
            return{MOBAccountLoading:false , MOBAccount:action.payload}  

        case MOB_ACCOUNT_TRANSACTION_FAIL :
            return {MOBAccountLoading:false , MOBAccounterror:action.payload}

        case MOB_ACCOUNT_TRANSACTION_RESET:
            return{MOBAccount:{}}
        
        default:
            return state

    }

} 


export const MOBTransactionUpdateReducer=(state={},action)=>{

    switch(action.type){
        case MOB_ACCOUNT_TRANSACTION_UPDATE_REQUEST :
            return{mobUpdateTrLoading:true  }

        case MOB_ACCOUNT_TRANSACTION_UPDATE_SUCCESS :
            return{mobUpdateTrLoading:false ,  mobUpdateTr:action.payload}  

        case MOB_ACCOUNT_TRANSACTION_UPDATE_FAIL :
            return {mobUpdateTrLoading:false , error:action.payload}

        case MOB_ACCOUNT_TRANSACTION_UPDATE_RESET:
            return{}
        
        default:
            return state

    }

}

